import { createSlice } from '@reduxjs/toolkit'
import player1 from '../../assets/img/player1.png';
import player2 from '../../assets/img/player2.png';
import player3 from '../../assets/img/player3.png';
const initialState = {
    name:'',
    currentHero:0,
    heros:[player1, player2, player3]
}

export const chatSlice = createSlice({
    name:"chat",
    initialState,
    reducers: {
        setName:(state,action) => {
            state.name = action.payload.name
        },
        setCurrentHero: (state,action) => {
            console.log(action.payload);
            if(action.payload.dir == 'next'){
                if(action.payload.currentHero < 2){
                    state.currentHero += 1
                }
            }else{
                if(action.payload.currentHero > 0){
                    state.currentHero -= 1
                }
            }
        },
    },
})

export const {setCurrentHero,setName} = chatSlice.actions
export default chatSlice.reducer


