import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import io from 'socket.io-client';
let socket = io('ws://localhost:3003');

const Chat = () => {
    const inputref = React.useRef(null);
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [message, setMessage] = useState('');
    const [myId, setMyId] = useState(null);
    const [users, setUsers] = useState([]);
    const [messages, setMessages] = useState([]);
    const selectedHero = useSelector((state) => state.chat.currentHero)
    const heros = useSelector((state) => state.chat.heros)
    const name = useSelector((state) => state.chat.name)
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {

        if(name === ''){
            console.log('name is empty')
            setRedirect(true)
        }else{
            // console.log('name is '+name)
            socket.on("connect_error", (err) => {
                console.log(`connect_error due to ${err.message}`);
            });
            socket.on('connect', (e) => {
                console.log('name is '+name)
                setIsConnected(true);
                // console.log(e)

                socket.emit('addUser',{
                    selectedHero,
                    heros,
                    name
                });
                
            });
            socket.on('enterToTheRoom', (e) =>{
                setUsers(e.users)
                setMessages(e.messages)
                setMyId(socket.id)
            })
            socket.on('leftTheRoom', (e) =>{
                // let newState =  users.filter(user => user.id != e.id);
                // setUsers(newState)
                // if(!messages.includes(e.message)){
                //     let newMsgs = messages.push({...e.message});
                //     setMessages(newMsgs)
                //     console.log(newMsgs,messages,)
                // }
            })
            socket.on('disconnect', () => {
                setIsConnected(false);
            });
            socket.on('updateMessage', (e) => {
                setUsers(e.users);
                if(e.messages){
                    setMessages(e.messages);
                    console.log(e.messages);
                }
            });
    
            socket.on('updatePosition', (data) => {
                // let newPosition = users.filter(item => {
                //     console.log(item.id == data.id);
                //     return item.id == data.id;
                // })
                console.log('new position',data.users);
                setUsers(data.users)
            });
        }
        
        return () => {
            socket.off('connect');
            socket.off('disconnect');
        };
    }, []);

    let handleChat = (e) => {
        if(e.keyCode == 13){
            socket.emit('sendMessage',{message:e.target.value,id:myId})
            inputref.current.value = ''
        }
        setMessage(e.target.value)
        console.log(message)
    }
    let sendMessage = () => {
        socket.emit('sendMessage',{message,id:myId})
        inputref.current.value = ''
        console.log(inputref)    
    }
    let messagesTemplate = messages.map((item)=> <div className="message" key={item.id}>{item.message}</div>)
    let usersTemplate = users.map((item)=> {
        let dir = (item.dir === 'left')?{transform: 'rotateY(180deg)'}:{}
        return <div className="user" style={{left:item.x+'px',top:item.y+'px'}} key={item.id}>
                    <span className="name">{item.name}</span>
                    <img style={{...dir}} src={item.hero} width="70" />
                    {item.message != ''?<span className="message">{item.message}</span>:null}
            </div>
    })
    const handleMove = (e) => {
        console.log(e.keyCode)
        let tempUsers = [...users];
        let item = tempUsers.find(item => item.id === myId);
        if(e.keyCode === 39 || e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 40){
            if(e.keyCode === 39){
                item.x += 10
                item.dir = 'right';
            }else if(e.keyCode === 37){
                item.x -= 10
                item.dir = 'left';
            }else if(e.keyCode === 38){
                item.y -= 10
            }else if(e.keyCode === 40){
                item.y += 10
            }
            socket.emit('updateHero',{id:myId,item});
            // setUsers(tempUsers); 
            // console.log(myId)   
        }
    }
    return <>
            {redirect?<Navigate to="/"/>:null}
            <div className="game" onKeyDownCapture={(e)=> handleMove(e)} tabIndex="0">
                <span className="status">الحالة:{isConnected?'نشط':'غير نشط'}</span>
                <span className="online-users">{users.length} نشطون</span>
                <div className="chat">
                    <div className="messages">
                        {messagesTemplate}
                    </div>
                    <div className="form-group">
                        <input type="text" ref={inputref} onKeyUp={(e) => handleChat(e)}/>
                        <input type="submit" onClick={sendMessage} value="ارسال" />
                    </div>
                </div>
            </div>
            {usersTemplate}
    </> ;
} 

export default Chat;