import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import {setCurrentHero,setName} from '../../store/slices/chatSlice';
import { useSelector, useDispatch } from 'react-redux'

const Login = () => {
    const dispatch = useDispatch();
    const selectedHero = useSelector((state) => state.chat.currentHero)
    const heros = useSelector((state) => state.chat.heros)
    const name = useSelector((state) => state.chat.name)
    const [redirect, setRedirect] = useState(false);
    const login = () => {
        if(name.trim() != ''){
            setRedirect(true)
        }
    }
   
    return <>
        <div className="hero section">
            {redirect?<Navigate to="/chat"/>:null}
            <button className="next" onClick={() => dispatch(setCurrentHero({dir:'next',currentHero:selectedHero}))}>التالي </button>
            <img src={heros[selectedHero]} alt="Hero" />
            <button className="prev" onClick={() => dispatch(setCurrentHero({dir:'prev',currentHero:selectedHero}))}>السابق</button>
            </div>
            <div className="form section">
            <div className="d-flex">
                <div className="wraper">
                    <input type="text" onChange={(e) => dispatch(setName({name:e.target.value}))}placeholder="اسم المستخدم"/>
                    <button onClick={login}>الدخول الان</button>
                </div>
            </div>
        </div>
    </> ;
} 
export default Login;