import React, { useState } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from './Components/Login/Login';
import Chat from './Components/Chat/Chat';

function App() {
  return (
    <Router>
      <div className="app">
        
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/chat" element={<Chat />} />
        </Routes>
      </div>

    </Router>
  );
}

export default App;